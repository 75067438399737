<section class="section banner">
    <div [@bannerTrigger] class="container">
        <div class="section-box-banner">
            <div class="content">
                <div>
                    <!-- <h1>Hola, mi nombre es</h1> -->
                    <h1>Hello! My name is</h1>
                </div>
                <div class='banner-title'>
                    <h2>Mehmet Demirci.</h2>
                    <h3>Full Stack Developer</h3>
                </div>
                <div class='banner-description'>
                    <p [innerHTML]='"Banner.Description" | translate' class="mt-4"></p>
                </div>
            </div>
            <div class="div-btn-banner">
                <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "banner", "click")' href="mailto:oa.mehmetdmrc@gmail.com" target="_black" class="main-btn mr-3 mb-3">
                    {{"Banner.ActionBtn" | translate}}
                </a>
                <a (click)='downloadCV();analyticsService.sendAnalyticEvent("click_show_cv", "banner", "click")' target="_black" class="main-btn mb-3">
                    {{"Banner.cvBtn" | translate}}
                </a>
            </div>
        </div>
    </div>
</section>
