<section class="section about" id='about'>
    <div class="container">
        <div class="section-box">
            <div class="about-title" data-aos="fade-up">
                <h3 class="section-title">
                    <span class='n-section-title'>01.</span> {{"AboutMe.Title" | translate}}
                </h3>
            </div>
            <div class="row">
                <div class="col-12 col-md-6 mb-4 mb-md-0">
                    <div class="about-description">
                        <p *ngFor='let text of "AboutMe.Paragraphs" | translate' [innerHTML]="text" data-aos="fade-up" data-aos-duration="1000">
                        </p>
                        <ul class="skills-list" data-aos="fade-up">
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">.Net, C# </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline">Angular, JS, TS, HTML, CSS </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Database</b> (MSSQL, PostgreSql, MySql, MongoDB) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>API</b> (Web API, RESTful, Swagger, MediatR, CQRS)</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Microservices</b> (RabbitMQ, MassTransit, gRPC, Ocelot) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Logging</b> (Serilog, ElasticSearch, LogStash, Kibana)</span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Deployment</b> (Docker, Kubernetes, Azure) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Testing</b> (xUnit, Moq, Postman) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Security</b> (JWT, OpenID, IdentityServer) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Resilience</b> (Polly, Health Checks, Policies) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>Performance</b> (Redis, HangFire, Ocelot) </span></li>
                            <li class="skill-element" data-aos="fade-up" data-aos-duration="1000"><span class="underline"><b>DevOps</b> (Office365, Azure, Git) </span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-duration="1000">
                    <div class="about-description">
                        <div class="mb-4">
                            <img (click)='analyticsService.sendAnalyticEvent("click_image", "about", "image")'  width="300" height="300" src="assets/images/me/bies.png" alt="Git User">
                        </div>
                        <div data-aos="fade-up">
                            <i class="icon fas fa-map-marker-alt m-2" style="width: 30px;"></i> {{"AboutMe.Address" | translate}}<br>
                            <i class="icon fas fa-phone m-2" style="width: 30px;"></i> {{"AboutMe.Phone" | translate}}<br>
                            <i class="icon fas fa-envelope m-2" style="width: 30px;"></i> {{"AboutMe.Email" | translate}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
