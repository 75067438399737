<footer>
    <div [@animateFooter]>
        <ul class="footer-left-bar d-none d-md-block">
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_github", "footer", "github")' href="https://github.com/HordeBies" target='_blank'>
                    <i class="fab fa-github"></i>
                </a>
            </li>
            <li>
                <a (click)='analyticsService.sendAnalyticEvent("click_linkedin", "footer", "linkedin")' href="https://www.linkedin.com/in/bies/" target='_blank'>
                    <i class="fab fa-linkedin-in"></i>
                </a>
            </li>
        </ul>
        <div class="footer-right-bar d-none d-md-block">
            <a (click)='analyticsService.sendAnalyticEvent("click_send_mail", "footer", "email")' href="mailto:oa.mehmetdmrc@gmail.com" target='_blank'>oa.mehmetdmrc@gmail.com </a>
        </div>
    </div>

    <div class="footer-credits text-center" >
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_bies", "footer", "github")' href="https://github.com/HordeBies" target="_blank" rel="nofollow noopener noreferrer">
            <div>Built with Angular by Mehmet Demirci</div>
        </a>
        <a (click)='analyticsService.sendAnalyticEvent("click_github_portfolio_brittany", "footer", "click")' href="https://brittanychiang.com/" target="_blank" rel="nofollow noopener noreferrer">
            <div class="mt-2">Designed with Gatsby by Brittany Chiang</div>
        </a>
    </div>

</footer>